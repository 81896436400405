import React from "react";
import "./EatingHabits.css";

interface EatingHabitsProps {
  setEatingHabitsDescription: (description: string) => void;
}

const EatingHabits: React.FC<EatingHabitsProps> = ({
  setEatingHabitsDescription,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEatingHabitsDescription(event.target.value);
  };

  return (
    <div className="eating-habits">
      <h3 className="eating-habits-title">Eating Habits Description</h3>
      <textarea className="eating-habits-textarea" onChange={handleChange} />
    </div>
  );
};

export default EatingHabits;
