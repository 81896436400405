import React from "react";
import "./WorkOut.css";

interface WorkOutProps {
  setWorkoutDescription: (description: string) => void;
}

const WorkOut: React.FC<WorkOutProps> = ({ setWorkoutDescription }) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setWorkoutDescription(event.target.value);
  };

  return (
    <div className="workout">
      <h3 className="workout-title">Workout Routine Description</h3>
      <textarea className="workout-textarea" onChange={handleChange} />
    </div>
  );
};

export default WorkOut;
