import React from "react";
import "./NavBar.css";

const NavBar: React.FC = () => {
  return (
    <nav className="nav">
      <div className="wrapper">
        <div className="logo">GymGains</div>
        <div className="tagline">see yourself in the future</div>
      </div>
    </nav>
  );
};

export default NavBar;
