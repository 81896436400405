import React from "react";
import "./Analysis.css";

interface AnalysisProps {
  description: string;
}

const Analysis: React.FC<AnalysisProps> = ({ description }) => {
  return (
    <div className="analysis-container">
      <h1 className="analysis-title">Analysis</h1>
      <p className="analysis-description">{description}</p>
    </div>
  );
};

export default Analysis;
