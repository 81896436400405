import React from "react";
import "./Negative.css";

interface NegativeProps {
  negative: boolean;
  setNegative: React.Dispatch<React.SetStateAction<boolean>>;
  disabled?: boolean;
}

const Negative: React.FC<NegativeProps> = ({
  negative,
  setNegative,
  disabled,
}) => {
  const [active, setActive] = React.useState(negative);

  const handleClick = () => {
    setNegative((prevState) => !prevState);
    setActive((prevState) => !prevState);
  };

  return (
    <button
      className={`negative-button ${active ? "button-active" : ""}`}
      onClick={handleClick}
      disabled={disabled}
    >
      {active ? "Flip back to Positive" : "Flip to see Negative"}
    </button>
  );
};

export default Negative;
