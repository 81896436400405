import { useEffect } from "react";
import "./App.css";
import Content from "./components/main/Content";
import NavBar from "./components/navbar/NavBar";

function App() {
  useEffect(() => {
    document.title = "GymGains";
  }, []);

  return (
    <div className="main">
      <NavBar />
      <Content />
    </div>
  );
}

export default App;
