import React, { useEffect } from "react";
import "./Content.css";
import Negative from "./parts/Negative";
import PersonalDetails from "./parts/PersonalDetails";
import WorkOut from "./parts/WorkOut";
import EatingHabits from "./parts/EatingHabits";
import { Mosaic } from "react-loading-indicators";
import Analysis from "./Analysis";

const Content: React.FC = () => {
  const [negative, setNegative] = React.useState(false);
  const [initialBodyType, setInitialBodyType] = React.useState<string>("");
  const [age, setAge] = React.useState<string>("");
  const [gender, setGender] = React.useState<string>("");
  const [workout, setWorkout] = React.useState<string>("");
  const [eating, setEating] = React.useState<string>("");

  const [loading, setLoading] = React.useState<boolean>(false);

  const [positiveImage, setPositiveImage] = React.useState<string>("");
  const [negativeImage, setNegativeImage] = React.useState<string>("");

  const [generate, setGenerate] = React.useState<boolean>(false);

  const [analysis, setAnalysis] = React.useState<string>("");

  useEffect(() => {
    if (initialBodyType && age && gender && workout && eating) {
      setGenerate(true);
    } else {
      setGenerate(false);
    }
  }, [initialBodyType, age, gender, workout, eating]);

  const handleSubmit = async () => {
    setLoading(true);
    setPositiveImage("");
    setNegativeImage("");
    setGenerate(false);

    await fetch(`${process.env.REACT_APP_API_URL}/get_analysis`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        initial_body_type: initialBodyType,
        age: age,
        gender: gender,
        workout_routine: workout,
        eating_habits: eating,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.analysis) {
          setAnalysis(data.analysis);
        } else {
          console.error("Analysis data is missing:", data);
        }
      });

    fetch(`${process.env.REACT_APP_API_URL}/submit`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        initial_body_type: initialBodyType,
        age: age,
        gender: gender,
        workout_routine: workout,
        eating_habits: eating,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setPositiveImage(data.positive_image_url);
        setNegativeImage(data.negative_image_url);
        setLoading(false);
        setGenerate(true);
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false);
        setGenerate(true);
      });
  };

  return (
    <div className="super-container">
      <div className="container">
        <Negative
          negative={negative}
          setNegative={setNegative}
          disabled={!positiveImage && !negativeImage}
        />
        <div className="content-wrapper">
          <div className="content-left">
            <PersonalDetails
              setInitialBodyType={setInitialBodyType}
              setAge={setAge}
              setGender={setGender}
            />
            <WorkOut setWorkoutDescription={setWorkout} />
            <EatingHabits setEatingHabitsDescription={setEating} />
            <button
              className="submit-button"
              onClick={handleSubmit}
              disabled={!generate}
            >
              Generate
            </button>
          </div>
          <div className="content-right">
            <div className="content-output">
              {positiveImage && negativeImage ? (
                <img
                  src={`${process.env.REACT_APP_API_URL}/get_image/${
                    negative ? negativeImage : positiveImage
                  }`}
                  alt="Result"
                  className="content-image"
                />
              ) : (
                <h3 className="content-output-title">
                  {loading ? (
                    <Mosaic
                      color={["#33CCCC", "#33CC36", "#B8CC33", "#FCCA00"]}
                    />
                  ) : (
                    <h2>No image generated</h2>
                  )}
                </h3>
              )}
            </div>
          </div>
        </div>
      </div>
      {analysis && <Analysis description={analysis} />}
    </div>
  );
};

export default Content;
