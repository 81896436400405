import React, { useState } from "react";
import "./PersonalDetails.css";

interface PersonalDetailsProps {
  setInitialBodyType: React.Dispatch<React.SetStateAction<string>>;
  setAge: React.Dispatch<React.SetStateAction<string>>;
  setGender: React.Dispatch<React.SetStateAction<string>>;
}

const PersonalDetails: React.FC<PersonalDetailsProps> = ({
  setInitialBodyType,
  setAge,
  setGender,
}) => {
  const [age, setLocalAge] = useState<string>("");
  const [gender, setLocalGender] = useState<string>("");
  const [initialBodyType, setLocalInitialBodyType] = useState<string>("");

  const handleInitialBodyTypeChange = (e: { target: { value: any } }) => {
    const value = e.target.value;
    setLocalInitialBodyType(value);
    setInitialBodyType(value);
  };

  const handleAgeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setLocalAge(value);
    setAge(value);
  };

  const handleGenderChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setLocalGender(value);
    setGender(value);
  };

  const ageOptions = [
    "18-20",
    "20-25",
    "25-30",
    "30-35",
    "35-40",
    "40-45",
    "45-50",
    "50-55",
    "55-60",
    "60-65",
    "65-70",
    "70-75",
    "75-80",
  ];

  return (
    <div className="personal-details">
      <h3 className="personal-details-title">Personal Details</h3>
      <div>
        <label className="personal-details-label">
          Initial Body Type:
          <input
            className="personal-details-input"
            value={initialBodyType}
            onChange={handleInitialBodyTypeChange}
          />
        </label>
      </div>
      <div className="personal-details-wrapper">
        <label className="personal-details-label">
          Age:
          <select
            className="personal-details-input"
            value={age}
            onChange={handleAgeChange}
          >
            <option value={""}>Select Age</option>
            {ageOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </label>
        <label className="personal-details-label">
          Gender:
          <select
            className="personal-details-input"
            value={gender}
            onChange={handleGenderChange}
          >
            <option value={""}>Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </label>
      </div>
    </div>
  );
};

export default PersonalDetails;
